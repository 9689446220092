<template>
  <div class="exercise">
    <h2>模拟练习 · 初级WEB前端工程师/20题</h2>
    <div class="main-wrap" v-if="currentRecord != null">
      <div class="main">
        <!-- 头部 -->
        <div class="block header">
          <div class="select">
            <span>第&nbsp;&nbsp;{{currentRecordIndex + 1}}&nbsp;&nbsp;题</span>
            <label></label>
            <span>正确率&nbsp;:<em>35%</em></span>
          </div>
          <div class="collect">
            <Button icon="icon-shoucang" type="solid" theme="info" size="mini">
              收藏
            </Button>
          </div>
        </div>
        <!-- 题目 -->
        <div class="block question">
          <!-- 标题 -->
          <h3>{{currentRecord.mcq.title}}</h3>
          <!-- 代码 -->
          <QuestionAnswerView
            v-if="currentRecord != null && currentRecord.mcq.codeSnippet != null && currentRecord.mcq.codeSnippet !== ''"
            :value="currentRecord.mcq.codeSnippet"
          />
          <!-- 选项 -->
          <el-checkbox-group v-model="answerData.value" :disabled="currentRecord.isCorrect != null">
            <el-checkbox
              v-for="(option, index) in currentRecord.options"
              :key="'' + currentRecord.mcq.title + option.id"
              :class="{
                'correct-answer': answerData.correctOptionIds.length > 0 && answerData.correctOptionIds.findIndex(item => item === option.id) > -1,
                'wrong': isWrongAnswer(option.id)
              }"
              :label="option.id"
            >
              <div class="option-wrap">
                <label>{{ index | optionText }}</label>
                <!-- 图片 -->
                <el-image v-if="option.type === 'IMAGE'" :src="'/api' + option.content" fit="contain" :preview-src-list="optionImages" style="height: 100px;"/>
                <!-- 文字 -->
                <p v-else>{{option.content}}</p>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <!-- 题目解读 & 相关阅读 -->
        <div class="block analyse">
          <div v-if="answerData.answerAnalyse != null">
            <h3><img src="../../../assets/images/answer-analyse-title.png"></h3>
            <QuestionAnswerView :value="answerData.answerAnalyse"/>
          </div>
        </div>
        <!-- 自动下一题 -->
        <div class="block automatic">
          <div>
            <el-checkbox v-model="answerData.autoNext">答对自动下一题</el-checkbox>
          </div>
          <div class="operating-button">
            <Button
              type="solid"
              theme="info"
              size="big"
              :disabled="currentRecordIndex === 0"
              @click="changeRecord(currentRecordIndex - 1)"
            >上一题</Button>
            <Button
              type="solid"
              theme="info"
              size="big"
              :disabled="currentRecordIndex >= records.length - 1"
              @click="changeRecord(currentRecordIndex + 1)"
            >跳过</Button>
            <Button
              v-if="!completed"
              type="solid"
              theme="function"
              size="big"
              :disabled="currentRecord.isCorrect != null || answerData.isWorking"
              @click="answer"
            >确认作答</Button>
            <Button
              v-else
              type="solid"
              theme="function"
              size="big"
              @click="$refs.startupReportDialog.open()"
            >查看报告</Button>
          </div>
        </div>
        <BreakLine/>
        <!-- 评论 -->
        <div class="block">
          <CommentWindow target-data-type="practice" :target-data-id="currentRecord.mcq.id" :target-data-serial-no="currentRecord.mcq.serialNo"/>
        </div>
      </div>
      <div class="side">
        <!-- 答题进度 -->
        <div class="block progress-wrap">
          <h4>当前进度</h4>
          <div class="progress">
            <el-progress :text-inside="true" :stroke-width="20" :percentage="answerRatio"></el-progress>
            <span>0</span>
            <span class="ys-right">{{records.length}}</span>
          </div>
          <div class="info"><label>正确率</label><span>{{correctRatio}}%</span></div>
          <div class="info"><label>答错数</label><em>{{faultCount}}</em></div>
          <div class="info"><label>剩余数</label><span>{{unAnswerCount}}</span></div>
          <div class="pk-wrap">
            <div v-if="inviteData.link == null">
              <img src="../../../assets/images/pk.png">
              <div>
                <span>请不要自己偷偷的进步</span>
                <p>邀请好友一起来答题学(P)习(K)。</p>
              </div>
            </div>
            <div v-else class="invite-link">{{inviteData.link}}</div>
            <div class="invite">
              <Button v-if="inviteData.link == null" type="solid" theme="info" size="big" :disabled="inviteData.isWorking" @click="invite">立即邀请</Button>
              <Button v-else type="outline" theme="function" size="big">复制邀请链接</Button>
            </div>
          </div>
        </div>
        <!-- 答题卡 -->
        <div class="ignore block question-card">
          <h4>答题卡</h4>
          <ul>
            <li
              v-for="(record,index) in records"
              :key="record.id"
              :class="{ current: currentRecordIndex === index, 'is-fault': record.isCorrect === false, 'has-answer': record.isCorrect != null }"
              @click="changeRecord(index)"
            >
              {{index + 1}}
            </li>
          </ul>
        </div>
        <!-- 参赛人员 -->
        <div v-if="reports.length > 1" class="ignore block user-list">
          <h4>参赛人员</h4>
          <ul>
            <li v-for="report in reports" :key="report.user.id">
              <img :src="report.user.avatar">
              <p><span>{{report.correctRatio}}%</span> / <em>{{report.faultCount}}</em></p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 练习完成提示 -->
    <StartupReportDialog ref="startupReportDialog" @confirm="$router.push({ name: 'PracticeReport', params: { practiceNo } })"/>
  </div>
</template>

<script>
import { fetchPracticeRecordList, answer, invite, getReports } from '@/api/user.practice'
import CommentWindow from '@/components/common/CommentWindow'
import BreakLine from '@/components/common/BreakLine'
import StartupReportDialog from '@/components/question/practice/StartupReportDialog'
import WSMixin from '@/components/mixins/WSMixin'
import QuestionAnswerView from '@/components/question/QuestionAnswerView'

export default {
  components: { QuestionAnswerView, StartupReportDialog, BreakLine, CommentWindow },
  mixins: [WSMixin],
  data () {
    return {
      loading: true,
      // 是否已完成答题
      completed: false,
      // 练习编号
      practiceNo: null,
      // 当前练习记录
      currentRecordIndex: 0,
      // 练习记录
      records: [],
      // 邀请数据
      inviteData: {
        isWorking: false,
        link: null
      },
      // 答案数据
      answerData: {
        // 是否作答中
        isWorking: false,
        // 答对是否自动下一题
        autoNext: true,
        // 正确答案
        correctOptionIds: [],
        // 答案分析
        answerAnalyse: null,
        // 已选
        value: []
      },
      // 参赛人员报告
      reports: []
    }
  },
  computed: {
    optionImages () {
      return this.currentRecord.options.map(item => '/api' + item.content)
    },
    // 当前练习记录
    currentRecord () {
      if (this.records.length === 0) {
        return null
      }
      return this.records[this.currentRecordIndex]
    },
    // 答题进度
    answerRatio () {
      const answerCount = this.records.filter(item => item.isCorrect != null).length
      return Math.round(answerCount / this.records.length * 10000) / 100
    },
    // 正确率
    correctRatio () {
      const hasAnswerRecord = this.records.filter(item => item.isCorrect)
      const ratio = hasAnswerRecord.length / this.records.length
      return Math.round(ratio * 10000) / 100
    },
    // 错误数
    faultCount () {
      return this.records.filter(item => item.isCorrect === false).length
    },
    // 剩余题目数量
    unAnswerCount () {
      return this.records.filter(item => item.isCorrect == null).length
    }
  },
  filters: {
    // 选项文案
    optionText (index) {
      const arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']
      return `${arr[index]}、`
    }
  },
  methods: {
    // 初始化
    init () {
      fetchPracticeRecordList(this.practiceNo)
        .then(data => {
          this.records = data
          this.changeRecord(0)
          // 获取参赛者报告
          this.__getReports()
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    // 切换题目
    changeRecord (targetIndex) {
      if (targetIndex < 0 || targetIndex > this.records.length - 1) {
        return
      }
      // 重置答案数据
      this.answerData.value = []
      this.answerData.answerAnalyse = null
      this.answerData.correctOptionIds = []
      // 切换
      this.currentRecordIndex = targetIndex
      this.$nextTick(() => {
        this.answerData.answerAnalyse = this.currentRecord.mcq.answerAnalyse
        this.answerData.correctOptionIds = this.currentRecord.correctOptionIds
        this.answerData.value = this.currentRecord.selectOptionIds == null ? [] : this.currentRecord.selectOptionIds.split(',').map(item => parseInt(item))
      })
    },
    // 答题
    answer () {
      if (this.answerData.value.length === 0) {
        return
      }
      if (this.answerData.isWorking) {
        return
      }
      this.answerData.isWorking = true
      answer({
        practiceNo: this.practiceNo,
        recordId: this.currentRecord.id,
        selectOptionIds: this.answerData.value.sort().join(',')
      })
        .then(data => {
          this.currentRecord.isCorrect = data.isCorrect
          this.currentRecord.selectOptionIds = this.answerData.value.sort().join(',')
          this.currentRecord.correctOptionIds = data.correctOptionIds
          this.currentRecord.mcq.answerAnalyse = data.answerAnalyse
          // 填充题目解析和正确答案
          if (!this.currentRecord.isCorrect) {
            this.answerData.answerAnalyse = data.answerAnalyse
            this.answerData.correctOptionIds = data.correctOptionIds
            return
          }
          // 答对自动下一题（跳转至下一道未回答的问题）
          if (this.answerData.autoNext) {
            const index = this.records.findIndex(item => item.isCorrect == null)
            if (index !== -1) {
              this.changeRecord(index)
            }
          }
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.answerData.isWorking = false
          this.completed = this.__completed()
          if (this.completed) {
            this.$refs.startupReportDialog.open()
          }
        })
    },
    // 邀请
    invite () {
      if (this.inviteData.isWorking) {
        return
      }
      this.inviteData.isWorking = true
      invite(this.practiceNo)
        .then(data => {
          this.inviteData.link = `${window.location.origin}/invite/practice/${this.practiceNo}?invite_code=${data.inviteCode}`
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.inviteData.isWorking = false
        })
    },
    // 判断是否回答错误
    isWrongAnswer (optionId) {
      if (this.answerData.correctOptionIds.length === 0) {
        return false
      }
      if (this.answerData.value.length === 0) {
        return false
      }
      // 选项不包含在正确答案中 && 选项包含在已选答案中
      if (
        this.answerData.correctOptionIds.findIndex(item => item === optionId) === -1 &&
        this.answerData.value.findIndex(item => item === optionId) > -1
      ) {
        return true
      }
      // 选项包含在正确答案中 && 选项不包含在已选答案中
      if (
        this.answerData.correctOptionIds.findIndex(item => item === optionId) > -1 &&
        this.answerData.value.findIndex(item => item === optionId) === -1
      ) {
        return true
      }
      return false
    },
    __getReports () {
      getReports(this.practiceNo)
        .then(data => {
          this.reports = data
          this.buildWebSocket(`/ws/practice/${this.practiceNo}`)
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    // 处理WS消息数据
    __handleWsMessage (data) {
      // 同步练习报告数据
      if (data.directive === 'REPORTS_DATA') {
        this.reports = data.data
        return
      }
      // 新人加入
      if (data.directive === 'JOIN_PRACTICE') {
        this.$notify.info({
          title: '消息',
          message: `${data.data.user.nickname}加入了练习！`
        })
        this.reports = data.data.records
      }
    },
    // 判断是否存在未答题目
    __completed () {
      return this.records.findIndex(item => item.isCorrect == null) === -1
    }
  },
  created () {
    this.practiceNo = this.$route.params.practiceNo
    this.init()
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/variables";
h2 {
  margin: 20px 0 10px 0;
  font-size: 14px;
  color: $font-color-second;
}
// 可忽略
.ignore {
  opacity: .5;
  transition: all ease .3s;
  &:hover {
    opacity: 1;
  }
}
.main-wrap {
  display: flex;
  //左侧整体
  .main {
    padding: 20px 0;
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    background-color: $background-color;
    .block {
      padding: 0 25px;
    }
    // 头部
    .header {
      display: flex;
      .select {
        width: 50%;
        span {
          font-size: 18px;
          em {
            color: #F53A19;
            font-style: normal;
            margin-left: 10px;
          }
        }
        label {
          font-size: 18px;
          margin: 0 20px 0 10px;
        }
      }
      // 收藏
      .collect {
        width: 50%;
        text-align: right;
      }
    }
    // 题目
    .question {
      border-bottom: 1px solid $font-color-forth;
      // 标题
      & > h3 {
        font-size: 22px;
        font-weight: normal;
      }
      // 选项
      .el-checkbox-group {
        width: 100%;
        box-sizing: border-box;
        /deep/ .el-checkbox {
          display: flex;
          align-items: flex-start;
          margin-bottom: 25px;
          // 正确答案
          &.correct-answer::before {
            font-family: pipafont;
            content: '\e664';
            font-size: 16px;
            position: absolute;
            top: 3px;
            left: 15px;
            color: $font-color-green;
          }
          // 答错
          &.wrong {
            .el-checkbox__label {
              color: $font-color-red;
            }
          }
          // 选中框
          .el-checkbox__input {
            width: 60px;
            flex-shrink: 0;
            display: flex;
            justify-content: right;
            // 选中状态
            &.is-checked {
              .el-checkbox__inner {
                border-color: $primary-color;
              }
            }
            // 禁用状态
            &.is-disabled {
              .el-checkbox__inner {
                background-color: #fff;
                border-color: #ccc;
                cursor: not-allowed;
              }
              &.is-checked .el-checkbox__inner{
                background-color: $primary-color;
                border-color: $primary-color;
              }
            }
            .el-checkbox__inner {
              overflow: hidden;
              width: 21px;
              height: 21px;
              border-radius: 50%;
              border-color: #ccc;
              &::after {
                border: 0;
                transform: none;
                font-family: pipafont;
                content: '\e664';
                top: 4px;
                left: 3px;
                font-weight: bold;
                font-size: 12px;
                color: #fff;
              }
            }
          }
          // 内容
          .el-checkbox__label {
            flex-grow: 1;
            overflow: hidden;
            color: $font-color;
            font-size: 15px;
            p {
              width: 100%;
              word-break: break-all;
              white-space: initial;
              margin: 0;
            }
          }
          .option-wrap {
            display: flex;
            align-items: flex-start;
          }
        }
      }
    }
    // 知识点解析 & 相关阅读
    .analyse {
      h3 {
        img {
          width: 80px;
        }
      }
    }
    // 上一题/下一题
    .automatic {
      display: flex;
      margin-top: 20px;
      align-items: center;
      div {
        flex-grow: 1;
        /deep/ .el-checkbox {
          .el-checkbox__inner {
            width: 18px;
            height: 18px;
            &::after {
              width: 5px;
              height: 12px;
              left: 5px;
              top: -1px;
              border-width: 2px;
            }
          }
          .el-checkbox__label {
            color: $font-color-third;
          }
        }
      }
      // 操作按钮
      .operating-button {
        width: 50%;
        flex-shrink: 0;
        text-align: right;
      }
    }
  }
  // 右侧整体
  .side {
    width: 260px;
    flex-shrink: 0;
    margin-left: 20px;
    .block {
      h4 {
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: normal;
      }
    }
    // 进度
    .progress-wrap {
      background-color: $background-color;
      padding: 20px 10px;
      box-sizing: border-box;
      // 进度条
      .progress {
        span {
          display: inline-block;
          width: 50%;
          margin-top: 5px;
          color: $font-color-third;
          &:last-of-type {
            text-align: right;
          }
        }
      }
      // 信息栏
      .info {
        margin-top: 10px;
        border-radius: 20px;
        padding: 10px 30px;
        box-sizing: border-box;
        background-color: $background-color-second;
        display: flex;
        label {
          width: 65px;
          flex-shrink: 0;
          color: $font-color-third;
        }
        em,span {
          flex-grow: 1;
        }
        em {
          color: $font-color-red;
          font-style: normal;
          font-weight: bold;
        }
        span {
          font-weight: bold;
        }
      }
      // PK
      .pk-wrap {
        font-size: 13px;
        margin: 15px 0 -10px 0;
        & > div {
          display: flex;
          img {
            width: 40px;
            height: 40px;
          }
          span {
            margin-left: 10px;
          }
          p {
            margin: 0 0 0 10px;
            font-size: 12px;
            color: $font-color-third;
          }
        }
        // 邀请链接
        .invite-link {
          height: 50px;
          box-sizing: border-box;
          line-height: 20px;
          overflow-y: hidden;
          background-color: $background-color-second;
          padding: 5px 10px;
          margin-top: 10px;
          word-break: break-all;
        }
        // 邀请
        .invite {
          display: flex;
          justify-content: center;
          margin-top: 5px;
          .el-button {
            width: 100%;
          }
        }
      }
    }
    // 答题卡
    .question-card {
      margin: 15px 0;
      padding: 10px 10px;
      box-sizing: border-box;
      background-color: $background-color;
      ul {
        width: 250px;
        flex-wrap: wrap ;
        margin: -10px 0 0 0;
        padding: 0;
        li {
          display: inline-block;
          width: 50px;
          height: 25px;
          line-height: 25px;
          margin: 7px 5px;
          text-align: center;
          border-radius: 12px;
          background-color: $background-color-second;
          position: relative;
          cursor: pointer;
          transition: all ease .3s;
          user-select: none;
          // 已回答
          &.has-answer {
            color: $font-color-third;
            &:hover {
              color: $font-color;
            }
          }
          // 回答错误
          &.is-fault {
            background-color: $font-color-red !important;
            color: #fff;
            &:hover {
              background-color: $font-color-red - 10;
              color: #fff;
            }
            &.current::after {
              border-bottom-color: $font-color-red;
            }
          }
          &:hover {
            background-color: $primary-color-hover;
            color: $primary-color;
          }
          // 当前答题
          &.current {
            background-color: $primary-color;
            color: #fff;
            &::after {
              content: '';
              position: absolute;
              left: 21px;
              bottom: -8px;
              width: 0;
              height: 0;
              border-bottom:6px solid $primary-color;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
            }
          }
        }
      }
    }
    // 参赛人员列表
    .user-list {
      padding: 15px 10px;
      box-sizing: border-box;
      background-color: $background-color;
      ul {
        padding: 0 5px;
        display: flex;
        margin:10px 0 0 0;
        width: 100%;
        flex-wrap: wrap;
        box-sizing: border-box;
        li {
          width: 33%;
          margin: 10px 0;
          display: flex;
          align-items: center;
          flex-direction: column;
          img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
          }
          p {
            font-size: 12px;
            color: $font-color-third;
            margin: 0;
            span {
              color: $font-color-third;
            }
            em {
              color: $font-color-red;
              font-style: normal;
            }
          }
        }
      }
    }
  }
}
</style>

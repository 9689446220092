<template>
  <el-dialog
    title="答题完成"
    custom-class="startup-report-dialog"
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    width="600px"
  >
    <p>您已完成答题练习，您可以再看看错题的答案解析，也可以前往查看练习报告！</p>
    <span slot="footer" class="dialog-footer">
      <Button type="solid" theme="info" size="big" @click="visible = false">再看看错题</Button>
      <Button type="solid" theme="function" size="big" @click="handleConfirm">查看详细报告</Button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'StartupReportDialog',
  data () {
    return {
      visible: false
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    handleConfirm () {
      this.visible = false
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss">
.el-dialog.startup-report-dialog {
  padding: 20px 0;
  border-radius: 20px;
  box-shadow: 0 0 10px -2px #333;
  .el-dialog__header {
    text-align: center;
    .el-dialog__title {
      font-size: 30px;
    }
  }
  .el-dialog__body {
    padding: 10px 50px;
    font-size: 18px;
    line-height: 30px;
  }
  .el-dialog__footer {
    display: flex;
    justify-content: center;
  }
}
</style>
